import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const ContentWrapper = styled.nav`
  display: ${props => (props.fullContent === true ? '' : 'flex')};
  justify-content: ${props => (props.fullContent === true ? '' : 'center')};
  width: 100%;
  overflow:clip;

  .content {
    height: 100%;
    max-width: ${props => (props.pageCode === 'account' ? '100% !important' : '')};
    width: 100%;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }
  .create-secret {
    z-index: 3;
    align-items: center;
    animation: 0.3s ease scaleup;
    background: ${props => props.theme.color.primaryColor};
    border: 3px solid white;
    border-radius: 30px;
    bottom: ${props => {
      if (props.whiteLabel.config && props.whiteLabel.config.zopimId) {
        return `${props.zopimHeight !== null ? props.zopimHeight + 10 : 40}px`;
      } else {
        return '20px';
      }
    }};
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 50px;
    height: 60px;
    justify-content: center;
    padding: 0;
    position: fixed;
    right: 20px;
    transition: background 0.3s ease;
    width: 60px;
  }
  @media (max-width: ${isMobileBreakpoint}px) {
    .create-secret {
      position: ${props => (props.pageCode === 'home' && (props.windowScrollY < 90 && props.whiteLabel.hasStarWhisperers) ? 'absolute' : 'fixed')};
      bottom: ${props => (props.pageCode === 'home' && props.windowScrollY < 90 && props.whiteLabel.hasStarWhisperers ? '246px' : (props.whiteLabel.config && props.whiteLabel.config.zopimId ? "132px" : "82px"))};
    }
  }
`;
